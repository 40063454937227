import { datadogRum } from '@datadog/browser-rum';
import packageInfo from '../../package.json';
import { env } from './env';

const {
  dataDogRum: { appId, clientToken, site, service },
  appEnvironment,
  appVersion,
  apiUrl,
} = env;

export const initDatadogRum = () => {
  datadogRum.init({
    applicationId: appId || '',
    clientToken: clientToken || '',
    site,
    service,
    env: appEnvironment,
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      apiUrl as string,
      /https:\/\/.*\.eu-west-2.amazoncognito\.com/,
      (url) => url.startsWith(apiUrl as string),
    ],
  });
  datadogRum.startSessionReplayRecording();
  datadogRum.addRumGlobalContext(
    'widget-version',
    packageInfo.dependencies['@convex-insurance/doclinks-widget'],
  );
};
