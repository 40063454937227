import { Flex } from '@chakra-ui/react';
import { Box } from '@convex-insurance/design_system/dist';
import PageLoader from '../../common/components/PageLoader';
import LandingCard from '../../features/landingCard/LandingCard';
import { colors } from '../../theme';

const LandingPage = () => {
  return (
    <PageLoader>
      <Box
        bgImage="url('/images/bg.svg')"
        bgColor={colors.dis.lightBlue}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        w="100%"
        h="100%"
        top={0}
        position="absolute"
      />
      <Box position="relative">
        <Flex mt="15vh" justifyContent="center">
          <LandingCard />
        </Flex>
      </Box>
    </PageLoader>
  );
};

export default LandingPage;
