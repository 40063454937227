import { useState } from 'react';
import { SupportedApps, DocLinksWidget } from '@convex-insurance/doclinks-widget';
import { Box } from '@convex-insurance/design_system';
import { Flex } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import PageLoader from '../../common/components/PageLoader';
import SearchForm from '../../features/search/SearchForm';
import { useReference } from '../../hooks/useReference';
import { env } from '../../config/env';

interface AuthProps {
  signOut: () => Promise<unknown>;
  user: { username: string; attributes: { email: string } };
}

const SearchResultsPage = () => {
  const reference = useReference();
  const [refetch, toggleRefetch] = useState(false);

  return (
    <PageLoader>
      <Box mb={10}>
        <Flex alignItems="center" flexDirection="column">
          <Box maxW={1500} w="100%" px={4}>
            <Box w="100%" maxW={370} pb={5} mt={10} mb={4}>
              <SearchForm iconButton={true} reference={reference} toggleRefetch={toggleRefetch} />
            </Box>
            <DocLinksWidget
              reference={reference}
              environment={env.appEnvironment || 'dev'}
              parent={SupportedApps.dis}
              refetchToggle={refetch}
              auth={Auth as unknown as AuthProps}
            />
          </Box>
        </Flex>
      </Box>
    </PageLoader>
  );
};

export default SearchResultsPage;
