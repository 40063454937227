import { MenuItem, MenuList, Header, Box, AppHeader } from '@convex-insurance/design_system';
import { Auth } from 'aws-amplify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AppRoutes } from '../../app/AppRoutes';
import { colors } from '../../theme';
import PageLoader from '../components/PageLoader';
import { selectAuth } from '../../features/auth/authSlice';
import { useAppSelector } from '../../app/hooks';

const AuthenticatedLayout = (): JSX.Element => {
  const { enableDoclinksRebranding } = useFlags();

  const { user } = useAppSelector(selectAuth);

  return (
    <PageLoader>
      <Box height="100%" minHeight="100vh" flexDirection="column" display="flex">
        <Box zIndex={99}>
          {enableDoclinksRebranding ? (
            <Header
              logo="doclinks_logo.svg"
              backgroundColor={colors.dis.blue}
              username={`${user?.firstName} ${user?.lastName}`}
              userMenuList={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      Auth.signOut();
                    }}
                    data-testid="header-logout-btn"
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              }
            />
          ) : (
            <AppHeader
              logo="logo.svg"
              borderColor={colors.dis.blue}
              heading="DocLinks"
              backgroundColor={colors.dis.white}
            />
          )}
        </Box>
        <Box flex="1 1 auto" bgColor={colors.dis.lightBlue}>
          <AppRoutes />
        </Box>
      </Box>
    </PageLoader>
  );
};

export default AuthenticatedLayout;
