import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertIcon, Box, Spinner } from '@convex-insurance/design_system';

interface PageLoaderProps extends PropsWithChildren {
  isLoading?: boolean;
  isError?: boolean;
}

export const ERROR_MESSAGE =
  'We could not load this page at this time. If this problem persists contact support for further assistance';

const PageLoader: React.FC<PageLoaderProps> = ({ isLoading, isError, children }) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => setSpinnerVisible(true), 1000);
      return () => timer && clearTimeout(timer);
    }
  }, [isLoading]);

  return isLoading || isError ? (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      {isLoading ? (
        spinnerVisible && <Spinner size="lg" />
      ) : (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{ERROR_MESSAGE}</AlertDescription>
        </Alert>
      )}
    </Box>
  ) : (
    <>{children}</>
  );
};

export default PageLoader;
