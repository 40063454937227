import { Box, Button, Text } from '@convex-insurance/design_system/dist';
import { Auth } from 'aws-amplify';

const LoginPage = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Text color="blackAlpha.700" fontWeight={300} fontSize="5xl" mb={3}>
        DocLinks
      </Text>
      <Button data-dd-action-name={'Login'} onClick={() => Auth.federatedSignIn()}>
        Login with SSO
      </Button>
    </Box>
  );
};

export default LoginPage;
